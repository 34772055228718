<template>
  <div>
    <v-container
      fill-height
      fluid
      grid-list-xl
    >
      <v-layout
        v-if="!loadingData"
        justify-center
        wrap
      >
        <v-flex
          md4
          style="margin-top: 50px"
          xs12
        >
          <material-card class="v-card-profile">
            <v-row
              align="end"
              class="fill-height"
            >
              <v-col
                align-self="start"
                class="pa-0"
                cols="12"
              >
                <avatar-picker
                  :image-src="getAvatar"
                  :image-style="{
                    'border-radius': '50%',
                    height: '10em'
                  }"
                  class="profile mx-auto d-block"
                  @input="onChangeImage($event)"
                />
                <v-slide-x-transition>
                  <div
                    v-if="
                      saving === true && saved === false
                    "
                  >
                    <v-btn
                      :loading="saving"
                      class="mx-auto d-block"
                      icon
                    >
                      <v-icon>mdi-content-save</v-icon>
                    </v-btn>
                  </div>
                </v-slide-x-transition>
              </v-col>
            </v-row>
            <v-list
              class="pa-0"
              two-line
            >
              <v-list-item href="#">
                <v-list-item-action>
                  <v-icon color="indigo">
                    mdi-city
                  </v-icon>
                </v-list-item-action>
                <v-list-item-content>
                  <v-list-item-title
                    v-text="getCompanyName"
                  />
                  <v-list-item-subtitle
                    v-text="
                      $vuetify.lang.t(
                        '$vuetify.profile.company'
                      )
                    "
                  />
                </v-list-item-content>
              </v-list-item>
              <v-divider inset />
              <v-list-item href="#">
                <v-list-item-action>
                  <v-icon color="indigo">
                    mdi-account
                  </v-icon>
                </v-list-item-action>
                <v-list-item-content>
                  <v-list-item-title v-text="getFullName" />
                  <v-list-item-subtitle
                    v-text="
                      $vuetify.lang.t(
                        '$vuetify.firstName'
                      )
                    "
                  />
                </v-list-item-content>
              </v-list-item>
              <v-divider inset />
              <v-list-item href="#">
                <v-list-item-action>
                  <v-icon color="indigo">
                    mdi-mail
                  </v-icon>
                </v-list-item-action>
                <v-list-item-content>
                  <v-list-item-title
                    v-text="userData.email ? userData.email: $vuetify.lang.t('$vuetify.no_defined')"
                  />
                  <v-list-item-subtitle
                    v-text="
                      $vuetify.lang.t('$vuetify.email')
                    "
                  />
                </v-list-item-content>
              </v-list-item>
            </v-list>
          </material-card>
        </v-flex>
        <v-flex
          md8
          xs12
        >
          <material-card
            :text="$vuetify.lang.t('$vuetify.profile.sub_profile')"
            :title="
              $vuetify.lang.t('$vuetify.profile.edit_profile')
            "
            color="color"
          >
            <v-form
              ref="form"
              v-model="formValid"
              class="my-10"
              lazy-validation
            >
              <v-container py-0>
                <v-layout wrap>
                  <v-flex
                    md6
                    xs12
                  >
                    <v-text-field
                      v-model="userData.firstName"
                      :label="$vuetify.lang.t('$vuetify.firstName')"
                      :rules="formRule.company"
                    />
                  </v-flex>
                  <v-flex
                    md6
                    xs12
                  >
                    <v-text-field
                      v-model="userData.lastName"
                      :label="$vuetify.lang.t('$vuetify.lastName')"
                      required
                    />
                  </v-flex>
                  <v-flex
                    md6
                    xs12
                  >

                    <v-text-field
                      v-model="userData.position.name"
                      :disabled="!enableEmail"
                      :label="$vuetify.lang.t('$vuetify.access.name')"
                    />
                  </v-flex>
                  <v-flex
                    md6
                    xs12
                  >
                    <v-text-field
                      v-model="userData.email"
                      :label="$vuetify.lang.t('$vuetify.email')"
                      autocomplete="off"
                      :rules="[localRules.emailError]"
                      type="email"
                    />
                  </v-flex>
                  <v-flex
                    md6
                    xs12
                  >
                    <vue-tel-input-vuetify
                      v-model="userData.phone"
                      v-bind="bindProps"
                      :error-messages="errorPhone"
                      :label="$vuetify.lang.t('$vuetify.phone')"
                      :placeholder="$vuetify.lang.t('$vuetify.phone_holder')"
                      :prefix="countrySelect ? `+` + countrySelect.dialCode : ``"
                      :select-label="$vuetify.lang.t('$vuetify.country')"
                      required
                      @input="onInput"
                      @country-changed="onCountry"
                    >
                      <template
                        #message="{ key, message }"
                      >
                        <slot
                          v-bind="{ key, message }"
                          name="label"
                        />
                        {{ message }}
                      </template>
                    </vue-tel-input-vuetify>
                  </v-flex>
                  <v-flex
                    text-xs-right
                    xs12
                  >
                    <v-btn
                      :disabled="!formValid || loading"
                      :loading="loading"
                      color="primary"
                      @click="updateProfile"
                    >
                      <v-icon>
                        mdi-content-save-all
                      </v-icon>
                      {{
                        $vuetify.lang.t(
                          '$vuetify.profile.btn_edit'
                        )
                      }}
                    </v-btn>
                  </v-flex>
                </v-layout>
              </v-container>
            </v-form>
          </material-card>
        </v-flex>
      </v-layout>
    </v-container>
  </div>
</template>

<script>
import { mapActions, mapGetters, mapState } from 'vuex'

export default {
  name: 'UserProfile',
  data () {
    return {
      localRules: {
        emailError: value => {
          if (value) {
            const pattern = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
            return pattern.test(value) || this.$vuetify.lang.t('$vuetify.rule.bad_email', [value])
          } else return true
        }
      },
      color: 'primary',
      formValid: false,
      enableEmail: false,
      loading: false,
      saving: false,
      loadingData: false,
      errorPhone: null,
      formRule: this.$rules,
      countrySelect: null
    }
  },
  computed: {
    ...mapState('auth', ['userData', 'pending']),
    ...mapState('company', ['saved', 'companies']),
    ...mapState('statics', ['arrayCountry']),
    ...mapGetters('auth', ['user', 'isManagerIn', 'isLoggedIn']),
    getFullName () {
      return `${this.user.firstName} ${this.user.lastName || ''}`
    },
    getCompanyName () {
      return `${this.user.company.name}`
    },
    getAvatar () {
      return this.userData.avatar ? this.userData.avatar.id ? this.userData.avatar.original_url : this.userData.avatar : '/assets/avatar/avatar-undefined.jpg'
    },
    bindProps () {
      return {
        mode: 'national',
        clearable: true,
        defaultCountry: this.user.company.country || 'US',
        disabledFetchingCountry: false,
        autocomplete: 'off',
        dropdownOptions: {
          disabledDialCode: false
        },
        inputOptions: {
          showDialCode: false
        }
      }
    }
  },
  async created () {
    this.loadingData = true
    await this.getUserData().then(() => {
      this.loadingData = false
    })
  },
  methods: {
    ...mapActions('auth', ['getUserData']),
    ...mapActions('user', ['updateUser']),
    onCountry (event) {
      this.userData.company.country = event.iso2
      this.countrySelect = event
    },
    async updateProfile () {
      this.loading = true
      await this.updateUser(this.userData).then(() => {
        if (this.saved) {
          this.loading = false
          const msg = this.$vuetify.lang.t(
            '$vuetify.messages.success_profile'
          )
          this.$Toast.fire({
            icon: 'success',
            title: msg
          })
        }
      }).catch(() => {
        this.loading = false
      })
    },
    async onChangeImage (file) {
      if (this.userData.avatar) {
        if (this.userData.avatar.id) {
          this.userData.avatar.original_url = `data:${file.type};base64,${file.base64}`
          return
        }
      }
      this.userData.avatar = `data:${file.type};base64,${file.base64}`
    },
    onInput (number, object) {
      const lang = this.$vuetify.lang
      if (object.valid) {
        this.userData.company.phone = number.replace(' ', '')
        this.errorPhone = null
      } else {
        this.errorPhone = lang.t('$vuetify.rule.bad_phone', [
          lang.t('$vuetify.phone')
        ])
      }
    },
    openConfirm () {
      const lang = this.$vuetify.lang
      this.$Swal
        .fire({
          title: lang.t('$vuetify.find_password'),
          input: 'password',
          inputAttributes: {
            minlength: 10,
            autocapitalize: 'off',
            autocorrect: 'off'
          },
          confirmButtonText: lang.t('$vuetify.actions.accept'),
          showLoaderOnConfirm: true,
          preConfirm: login => {
          },
          allowOutsideClick: () => !this.$Swal.isLoading()
        })
        .then(result => {
          if (result.isConfirmed) {
            this.enableEmail = true
          }
        })
    }
  }
}
</script>
<style scoped>
.profile {
  width: 150px;
  height: 150px;
  border-radius: 50%;
}
</style>
