<template>
  <div>
    <app-loading v-show="loadingData"/>
    <company-profile v-show="isManagerIn"/>
    <user-profile v-show="!isManagerIn"/>
  </div>
</template>

<script>
import { mapActions, mapGetters, mapState } from 'vuex'
import CompanyProfile from './CompanyProfile'
import UserProfile from './UserProfile'

export default {
  name: 'Profile',
  components: { UserProfile, CompanyProfile },
  data () {
    return {
      loadingData: false
    }
  },
  computed: {
    ...mapState('auth', ['userData', 'pending']),
    ...mapState('company', ['saved', 'companies']),
    ...mapState('statics', ['arrayCountry']),
    ...mapGetters('auth', ['user', 'isManagerIn', 'isLoggedIn']),
    getFullName () {
      return `${this.user.firstName} ${this.user.lastName || ''}`
    },
    getCompanyName () {
      return `${this.user.company.name}`
    },
    getAvatar () {
      return this.user.company.logo ? this.user.company.logo.original_url : '/assets/avatar/avatar-undefined.jpg'
    },
    bindProps () {
      return {
        mode: 'national',
        clearable: true,
        defaultCountry: this.user.company.country || 'US',
        disabledFetchingCountry: false,
        autocomplete: 'off',
        dropdownOptions: {
          disabledDialCode: false
        },
        inputOptions: {
          showDialCode: false
        }
      }
    }
  },
  async created () {
    this.loadingData = true
    await this.getUserData().then(() => {
      this.loadingData = false
    })
  },
  methods: {
    ...mapActions('auth', ['getUserData']),
    ...mapActions('company', ['updateCompany', 'updateLogo']),
    onCountry (event) {
      this.userData.company.country = event.iso2
      this.countrySelect = event
    },
    async updateProfile () {
      this.loading = true
      await this.updateCompany(this.userData.company).then(() => {
        if (this.saved) {
          this.loading = false
          const msg = this.$vuetify.lang.t(
            '$vuetify.messages.success_profile'
          )
          this.$Toast.fire({
            icon: 'success',
            title: msg
          })
        }
      })
    },
    async onChangeImage (file) {
      this.saving = true
      const id = this.userData.company.id
      await this.updateLogo({ id, file }).then(() => {
        if (this.saved) {
          const msg = this.$vuetify.lang.t(
            '$vuetify.messages.success_avatar'
          )
          this.$Toast.fire({
            icon: 'success',
            title: msg
          })
        }
      })
    },
    onInput (number, object) {
      const lang = this.$vuetify.lang
      if (object.valid) {
        this.userData.company.phone = number.replace(' ', '')
        this.errorPhone = null
      } else {
        this.errorPhone = lang.t('$vuetify.rule.bad_phone', [
          lang.t('$vuetify.phone')
        ])
      }
    },
    openConfirm () {
      const lang = this.$vuetify.lang
      this.$Swal
        .fire({
          title: lang.t('$vuetify.find_password'),
          input: 'password',
          inputAttributes: {
            minlength: 10,
            autocapitalize: 'off',
            autocorrect: 'off'
          },
          confirmButtonText: lang.t('$vuetify.actions.accept'),
          showLoaderOnConfirm: true,
          preConfirm: login => {
          },
          allowOutsideClick: () => !this.$Swal.isLoading()
        })
        .then(result => {
          if (result.isConfirmed) {
            this.enableEmail = true
          }
        })
    }
  }
}
</script>
<style scoped>
.profile {
  width: 150px;
  height: 150px;
  border-radius: 50%;
}
</style>
