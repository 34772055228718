var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('v-container',{attrs:{"fill-height":"","fluid":"","grid-list-xl":""}},[(!_vm.loadingData)?_c('v-layout',{attrs:{"justify-center":"","wrap":""}},[_c('v-flex',{staticStyle:{"margin-top":"50px"},attrs:{"md4":"","xs12":""}},[_c('material-card',{staticClass:"v-card-profile",scopedSlots:_vm._u([{key:"actions",fn:function(){return [_c('v-spacer'),_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function({ on, attrs }){return [_c('v-btn',{attrs:{"icon":""}},[_c('v-icon',_vm._g(_vm._b({attrs:{"color":"red"}},'v-icon',attrs,false),on),[_vm._v(" mdi-delete ")])],1)]}}],null,false,663300941)},[_c('span',[_vm._v(_vm._s(_vm.$vuetify.lang.t( '$vuetify.tips.account_delete' )))])])]},proxy:true}],null,false,3825679248)},[_c('v-row',{staticClass:"fill-height",attrs:{"align":"end"}},[_c('v-col',{staticClass:"pa-0",attrs:{"align-self":"start","cols":"12"}},[_c('avatar-picker',{staticClass:"profile mx-auto d-block",attrs:{"image-src":_vm.getAvatar,"image-style":{
                  'border-radius': '50%',
                  height: '10em'
                }},on:{"input":function($event){return _vm.onChangeImage($event)}}}),_c('v-slide-x-transition',[(
                    _vm.saving === true && _vm.saved === false
                  )?_c('div',[_c('v-btn',{staticClass:"mx-auto d-block",attrs:{"loading":_vm.saving,"icon":""}},[_c('v-icon',[_vm._v("mdi-content-save")])],1)],1):_vm._e()])],1)],1),_c('v-list',{staticClass:"pa-0",attrs:{"two-line":""}},[_c('v-list-item',{attrs:{"href":"#"}},[_c('v-list-item-action',[_c('v-icon',{attrs:{"color":"indigo"}},[_vm._v(" mdi-city ")])],1),_c('v-list-item-content',[_c('v-list-item-title',{domProps:{"textContent":_vm._s(_vm.getCompanyName)}}),_c('v-list-item-subtitle',{domProps:{"textContent":_vm._s(
                    _vm.$vuetify.lang.t(
                      '$vuetify.profile.company'
                    )
                  )}})],1)],1),_c('v-divider',{attrs:{"inset":""}}),_c('v-list-item',{attrs:{"href":"#"}},[_c('v-list-item-action',[_c('v-icon',{attrs:{"color":"indigo"}},[_vm._v(" mdi-account ")])],1),_c('v-list-item-content',[_c('v-list-item-title',{domProps:{"textContent":_vm._s(_vm.getFullName)}}),_c('v-list-item-subtitle',{domProps:{"textContent":_vm._s(
                    _vm.$vuetify.lang.t(
                      '$vuetify.firstName'
                    )
                  )}})],1)],1),_c('v-divider',{attrs:{"inset":""}}),_c('v-list-item',{attrs:{"href":"#"}},[_c('v-list-item-action',[_c('v-icon',{attrs:{"color":"indigo"}},[_vm._v(" mdi-mail ")])],1),_c('v-list-item-content',[_c('v-list-item-title',{domProps:{"textContent":_vm._s(_vm.userData.company.email)}}),_c('v-list-item-subtitle',{domProps:{"textContent":_vm._s(
                    _vm.$vuetify.lang.t('$vuetify.email')
                  )}})],1)],1)],1)],1)],1),_c('v-flex',{attrs:{"md8":"","xs12":""}},[_c('material-card',{attrs:{"text":_vm.$vuetify.lang.t('$vuetify.profile.sub_profile'),"title":_vm.$vuetify.lang.t('$vuetify.profile.edit_profile'),"color":"color"}},[_c('v-form',{ref:"form",staticClass:"my-10",attrs:{"lazy-validation":""},model:{value:(_vm.formValid),callback:function ($$v) {_vm.formValid=$$v},expression:"formValid"}},[_c('v-container',{attrs:{"py-0":""}},[_c('v-layout',{attrs:{"wrap":""}},[_c('v-flex',{attrs:{"md6":"","xs12":""}},[_c('v-text-field',{attrs:{"label":_vm.$vuetify.lang.t(
                        '$vuetify.company'
                      ),"rules":_vm.formRule.company},model:{value:(_vm.userData.company.name),callback:function ($$v) {_vm.$set(_vm.userData.company, "name", $$v)},expression:"userData.company.name"}})],1),_c('v-flex',{attrs:{"md6":"","xs12":""}},[_c('v-text-field',{attrs:{"disabled":!_vm.enableEmail,"label":_vm.$vuetify.lang.t(
                        '$vuetify.email'
                      ),"rules":_vm.formRule.email},model:{value:(_vm.userData.company.email),callback:function ($$v) {_vm.$set(_vm.userData.company, "email", $$v)},expression:"userData.company.email"}}),_c('v-btn',{attrs:{"color":"secondary"},on:{"click":_vm.openConfirm}},[_vm._v(" "+_vm._s(_vm.$vuetify.lang.t( '$vuetify.actions.change' ))+" ")])],1),_c('v-flex',{attrs:{"md6":"","xs12":""}},[_c('vue-tel-input-vuetify',_vm._b({attrs:{"error-messages":_vm.errorPhone,"label":_vm.$vuetify.lang.t(
                        '$vuetify.phone'
                      ),"placeholder":_vm.$vuetify.lang.t(
                        '$vuetify.phone_holder'
                      ),"prefix":_vm.countrySelect
                        ? `+` +
                          _vm.countrySelect.dialCode
                        : ``,"select-label":_vm.$vuetify.lang.t(
                        '$vuetify.country'
                      ),"required":""},on:{"input":_vm.onInput,"country-changed":_vm.onCountry},scopedSlots:_vm._u([{key:"message",fn:function({ key, message }){return [_vm._t("label",null,null,{ key, message }),_vm._v(" "+_vm._s(message)+" ")]}}],null,true),model:{value:(_vm.userData.company.phone),callback:function ($$v) {_vm.$set(_vm.userData.company, "phone", $$v)},expression:"userData.company.phone"}},'vue-tel-input-vuetify',_vm.bindProps,false))],1),_c('v-flex',{attrs:{"md6":"","xs12":""}},[_c('v-autocomplete',{attrs:{"items":_vm.arrayCountry,"label":_vm.$vuetify.lang.t(
                        '$vuetify.country'
                      ),"rules":_vm.formRule.country,"item-text":"name","item-value":"id","required":""},scopedSlots:_vm._u([{key:"item",fn:function(data){return [(
                          typeof data.item !==
                            'object'
                        )?[_c('v-list-item-content',{domProps:{"textContent":_vm._s(data.item)}})]:[_c('v-list-item-avatar',[_vm._v(" "+_vm._s(data.item.emoji)+" ")]),_c('v-list-item-content',[_c('v-list-item-title',[_vm._v(" "+_vm._s(data.item.name)+" ")])],1)]]}}],null,false,2571829411),model:{value:(_vm.userData.company.country),callback:function ($$v) {_vm.$set(_vm.userData.company, "country", $$v)},expression:"userData.company.country"}})],1),_c('v-flex',{attrs:{"md12":"","xs12":""}},[_c('v-text-field',{attrs:{"label":_vm.$vuetify.lang.t(
                        '$vuetify.address'
                      ),"rules":_vm.formRule.required,"required":""},model:{value:(_vm.userData.company.address),callback:function ($$v) {_vm.$set(_vm.userData.company, "address", $$v)},expression:"userData.company.address"}})],1),_c('v-flex',{attrs:{"md12":"","xs12":""}},[_c('v-text-field',{attrs:{"label":_vm.$vuetify.lang.t(
                        '$vuetify.slogan'
                      )},model:{value:(_vm.userData.company.slogan),callback:function ($$v) {_vm.$set(_vm.userData.company, "slogan", $$v)},expression:"userData.company.slogan"}})],1),_c('v-flex',{attrs:{"md12":"","xs12":""}},[_c('v-text-field',{attrs:{"label":_vm.$vuetify.lang.t(
                        '$vuetify.footer'
                      )},model:{value:(_vm.userData.company.footer),callback:function ($$v) {_vm.$set(_vm.userData.company, "footer", $$v)},expression:"userData.company.footer"}})],1),_c('v-flex',{attrs:{"text-xs-right":"","xs12":""}},[_c('v-btn',{attrs:{"disabled":!_vm.formValid || _vm.loading,"loading":_vm.loading,"color":"primary"},on:{"click":_vm.updateProfile}},[_c('v-icon',[_vm._v(" mdi-content-save-all ")]),_vm._v(" "+_vm._s(_vm.$vuetify.lang.t( '$vuetify.profile.btn_edit' ))+" ")],1)],1)],1)],1)],1)],1)],1)],1):_vm._e()],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }